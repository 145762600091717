import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О нашей <span>компании</span></h2>
                        <p className={styles.text}>&#8195;Уже более трех лет мы успешно осуществляем дорожно-строительные работы любой сложности и комплексное благоустройство различных частных и муниципальных территорий .</p>
                        <p className={styles.text}>&#8195;Основными принципами нашей деятельности является: надёжность и стабильность в работе с нашими клиентами, ответственный подход наших сотрудников к выполнению своей работы, ориентированность на долгосрочное сотрудничество с партнёрами компании.</p>
                        <p className={styles.text}>&#8195;Главный актив компании ГРАДИТЭЛ - люди. Квалифицированный персонал компании - результат качественного отбора и непрерывного обучения кадров. Мы уделяем особое внимание сотрудникам нашей компании, поскольку именно они обеспечивают качество работы с нашими клиентами и постоянство наших клиентов в выборе нашей компании как благонадёжного партнера.</p>
                    </motion.div>
            </div>
        </motion.section>
        
        
    )
}