import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Questions } from '../../components/Questions/Questions'
import {Advantages} from '../../components/Advantages/Advantages'
import { Certificates } from '../../components/Certificates/Certificates'
import {Services} from '../../components/Services/Services'
import {Discount} from '../../components/Discount/Discount'
import { Practical } from '../../components/Practical/Practical'
import { Repair } from '../../components/Repair/Repair'
import { Laying } from '../../components/Laying/Laying'

export const Main = ({isOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs/>
            <Services
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Laying/>
            <Certificates
                isImageOpen={isImageOpen}
            />
            <Repair/>
            <Questions/>
            <Discount/>
            <Practical/>
        </section>
    )
}