import React from 'react'
import { motion } from "framer-motion"
import styles from './Repair.module.scss'
import image from '../../image/Repair/img_1.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Repair = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='Obuchenie'             
            >
            <div className={styles.container}>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Ремонт ям и <span>санация трещин</span></h2>
                        <p className={styles.text}>&#8195;Выполнение ремонтных работ дорожного асфальтового покрытия возможно только в случае, если его исходное состояние не слишком разрушено. Ямочный ремонт и санация трещин помогают восстановить целостность дорожного покрытия, продлить срок его службы. Мы предлагаем комплекс работ, направленных на полное восстановление асфальта.</p>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
        
        
    )
}