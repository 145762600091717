import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Высокое качество</h3>
                        <p className={styles.item_text}>Наши специалисты обладают большим практическим опытом и знаниями в области дородного строительства. Мы всегда выполняем поставленную задачу качественно.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Точно в срок </h3>
                        <p className={styles.item_text}>Всегда точно соблюдаем сроки закрепленные договором. Мы выполним работы тогда, когда это удобно вам в любой день и любое время, без выходных и праздников.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Индивидуальный подход</h3>
                        <p className={styles.item_text}>Мы индивидуально подходим к каждому проекту и клиенту. Учитываем потребности и требования  и стремится предоставить самое эффективное решение, соответствующее потребностям и бюджету.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Доступные цены</h3>
                        <p className={styles.item_text}>Cобственное производство, большой парк техники и отсутствие посредников позволяют нам держать цены на доступном для клиентов уровне.</p>
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}