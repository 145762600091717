import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>Производство, продажа и&#160;укладка асфальта</motion.h1>
                            <div className={styles.line}></div>
                            <motion.p className={styles.text} variants={subtitleAnimation}>Качественные дороги за разумные деньги</motion.p>
                            <button className={styles.button} type='button' onClick={isOpen}>оставить заявку</button>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}