import React from 'react'
import { motion } from "framer-motion"
import styles from './Services.module.scss'
import icon_1 from '../../image/Practical/img_1.jpg'
import icon_2 from '../../image/Practical/img_2.jpg'
import icon_3 from '../../image/Practical/img_3.jpg'
import icon_4 from '../../image/Practical/img_4.webp'
import icon_5 from '../../image/Practical/img_5.jpg'
import icon_6 from '../../image/Practical/img_6.jpg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Наши услуги</motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>Продажа асфальта</h3>
                            <img className={styles.item_image}  src={icon_1} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>асфальтирование дорог</h3>
                            <img className={styles.item_image}  src={icon_2} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>асфальтовая крошка</h3>
                            <img className={styles.item_image}  src={icon_3} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>ЯМОЧНЫЙ РЕМОНТ</h3>
                            <img className={styles.item_image}  src={icon_4} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>Благоустройство</h3>
                            <img className={styles.item_image}  src={icon_5} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                        <li className={styles.item}>
                            <h3 className={styles.item_title}>Аренда техники</h3>
                            <img className={styles.item_image}  src={icon_6} alt='дом'/>
                            <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ</button>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}