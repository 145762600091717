import React, {useCallback} from 'react'
import styles from './CardImage.module.scss'
import { motion } from "framer-motion"

const boxAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .7}
    } 
}


export const CardImage = ({item, isImageOpen, index, arr}) => {

    const cardClick = useCallback(() => {
        isImageOpen(index, arr)
    }, [isImageOpen, arr, index])

    return (
        <motion.li className={styles.item}
            onClick={cardClick}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.01, once: true}}
            variants={boxAnimation} 
            >
            <div className={styles.box_image}>
                <img className={styles.image} src={item.image} alt={item.title}/>
            </div>
            <p className={styles.text}>{item.title}</p> 
        </motion.li>
    )
}