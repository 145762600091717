import React, {useState, useEffect, useCallback} from 'react'
import styles from './Certificates.module.scss'
import { motion } from "framer-motion"
import { arrCertificates } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import { CardImage } from '../CardImage/CardImage'
import ScreenSize from '../../hooks/ScreenSize'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Certificates = ({isImageOpen}) => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)


    useEffect(() => {
        const getCards = () => {
            if (width >= 980) {
                setMoviesTotal(6);
                setAddMovies(3);
                } else if (width < 980 && width >= 640) {
                    setMoviesTotal(6);
                    setAddMovies(2);
                        } else if (width <= 639) {
                            setMoviesTotal(6);
                            setAddMovies(1);
            }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])

    return (
        <motion.section 
        className={styles.main} 
        id='Photo'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Примеры <span> работ</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                {arrCertificates.map((item, index) =>{
                        const keyUid = uuidv4()
                            if (index + 1 <= moviesTotal) {
                                return(
                                    <CardImage
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen}
                                    arr={arrCertificates} 
                                />
                                ) 
                            }else {
                                return ''
                            }
                    })}
                </motion.ul>
                {moviesTotal < arrCertificates.length && <button  className={styles.button} onClick={addCards}>Ещё работы</button>}
        </div>   
    </motion.section>
    )
}